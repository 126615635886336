@import "@fontsource/poppins";
@import "@fontsource/rubik";
@import "@fontsource/inter";
@import "@fontsource/dm-sans";
@import "@fontsource/fasthand";

html,
body {
  font-family: "Rubik";
}

p {
  margin: unset;
}
