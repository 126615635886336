@import "~antd/dist/antd.css";

.ant-picker-dropdown,
ant-picker-dropdown-range {
  .ant-picker-range-arrow {
    display: none;
  }

  .ant-picker-panel-container {
    position: relative;
    bottom: 6px;
    border-radius: 4px;
  }
}

.ant-picker-panels>*:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels>*:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-panels>*:last-child {
  display: none;
}

.ant-picker-footer-extra>div {
  flex-wrap: wrap !important;
}

.ant-picker-header-view {
  padding-top: 3px;
  font-size: 16px;
  font-weight: 600;
}

// Loading
.ant-spin-dot-item {
  background: linear-gradient(94.18deg,
      #c58aff 1.63%,
      #00adfb 64.37%,
      #00d5c9 93.96%,
      #00de8c 125.59%);
}

.ant-form-item-required {
  &::before {
    content: none !important;
  }
}

// Custom tabs
.ant-tabs-tab {
  transition: all 0.3s ease-in-out;

  :hover {
    .ant-tabs-tab-btn {
      color: #1890ff !important;
    }
  }
}

.ant-tabs-nav {
  border-radius: 4px;
  border-bottom: none !important;

  &:before {
    border-bottom: none !important;
  }
}

.ant-tabs-nav-wrap {
  height: 76px;
  padding: 0px 4px;

  justify-content: flex-start !important;
  position: relative;

  .ant-tabs-nav-list {
    align-items: flex-end;
  }

  .ant-tabs-tab-btn {
    font: normal 400 20px/100% "Rubik";
    color: rgba(8, 10, 37, 0.7);
  }
}

.ant-tabs-ink-bar {
  background: #7e00fd;
}
